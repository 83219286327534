import firebase from 'firebase/app'
import 'firebase/firestore'

// Get a Firestore instance
export const db = firebase
  .initializeApp({
    apiKey: 'AIzaSyAZ2Oo9ZGOfKtrQmam8MVKiYxwzwW_Fv40',
    authDomain: 'game7-in.firebaseapp.com',
    databaseURL: 'https://game7-in.firebaseio.com',
    projectId: 'game7-in',
    storageBucket: 'game7-in.appspot.com',
    messagingSenderId: '406712580436',
    appId: '1:406712580436:web:ed0e6e30388ee73f5cf0c1',
    measurementId: 'G-BB8CP0BNKX',
  })
  .firestore()
export default db
