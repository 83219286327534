<template>
  <div>
    <chat-window
      :height="'calc(100vh - 90px)'"
      :current-user-id="currentUserId"
      :rooms="rooms"
      :loading-rooms="!data_loaded"
      :rooms-loaded="data_loaded"
      :room-id="roomId"
      :show-add-room="false"
      :messages="messages"
      :messages-loaded="messagesLoaded"
      :accepted-files="acceptedFiles"
      :show-audio="false"
      :show-footer="can_post"
      @fetch-messages="fetchMessages"
      @send-message="sendMessage"
      @edit-message="editMessage"
      @delete-message="deleteMessage"
      @send-message-reaction="sendMessageReaction"
      @open-file="openFile"
    />
  </div>
</template>

<script>
import firebase from 'firebase'
import ChatWindow from 'vue-advanced-chat'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import moment from 'moment'
import { db } from '@/libs/firebase'

export default {
  components: {
    ChatWindow,
  },
  data() {
    return {
      data_loaded: false,
      userData: JSON.parse(localStorage.getItem('userData')),
      currentUserId: '', // this.userData.id,
      rooms: [],
      roomId: '',
      selectedRoom: '',
      roomsListeners: [],
      messages: [],
      messagesPerPage: 20,
      messagesLoaded: false,
      startMessages: null,
      endMessages: null,
      listeners: [],
      can_post: true,
      roomsRef: {},

      acceptedFiles: 'image/png, image/jpeg, application/pdf',
      styles: {
        general: {
          colorSpinner: '#f62739',
          borderStyle: '1px solid #111115',
          backgroundInput: '#2e2e39',
        },
        header: {
          background: '#111115',
        },
        sidemenu: {
          background: '#0c0c0f',
          backgroundHover: '#1c1c23',
          backgroundActive: '#16161c',
        },
        content: {
          background: '#16161c',
        },
        footer: {
          background: '#111115',
          backgroundReply: 'rgba(0, 0, 0, 0.08)',
        },
      },
      match: {
        id: '',
        title: false,
      },
      dbUnsubscribe: false,
      breadcrumbs: [],
    }
  },
  computed: {},
  created() {
    this.currentUserId = this.userData.id
    this.fetchRooms()
    this.updateUserOnlineStatus()
    this.roomsRef = db.collection('pubgm_match_chats')
    this.filesRef = firebase.storage().ref().child('files')
  },
  destroyed() {
    this.resetRooms()
    this.resetMessages()
  },

  beforeDestroy() {
    if (typeof this.dbUnsubscribe === 'function') {
      this.dbUnsubscribe()
    }
  },
  methods: {
    syncFire() {
      this.dbChat = db.collection('pubgm_org_slots')
      this.dbUnsubscribe = this.dbChat.doc(this.match.org_id).onSnapshot(doc => {
        // console.log(doc.data())
        const lastRoomId = this.selectedRoom
        if (doc.data()) {
          if (this.data_loaded) {
            this.resetRooms()
            this.fetchRooms()
            this.roomId = lastRoomId
          }
        }
      })
    },
    resetRooms() {
      this.rooms = []
      this.messages = []
      this.messages_loaded = false
      this.roomsListeners.forEach(listener => listener())
      this.roomsListeners = []
    },
    resetMessages() {
      this.messages = []
      this.messagesLoaded = false
      this.startMessages = null
      this.endMessages = null
      this.listeners.forEach(listener => listener())
      this.listeners = []
    },
    async fetchRooms() {
      let url = `/organizer/chat/getRooms?organization=${localStorage.getItem('organizationSelected')}`
      if (this.$route.query.t) {
        url += `&tournament=${this.$route.query.t}`
      }
      const response = await this.$http.get(url)
      if (!this.data_loaded) {
        this.rooms = response.data.data.rooms
        this.match = response.data.data.match

        this.data_loaded = true
        this.syncFire()
      } else {
        this.match = response.data.data.match
        this.rooms = response.data.data.rooms
      }
      // console.log('rooms refreshed')

      this.rooms.forEach(room => {
        this.listenLastMessage(room)
      })
    },
    listenLastMessage(room) {
      const listener = this.messagesRef(room.roomId)
        .orderBy('timestamp', 'desc')
        .limit(1)
        .onSnapshot(messages => {
          // this.incrementDbCounter('Listen Last Room Message', messages.size)
          messages.forEach(message => {
            const lastMessage = this.formatLastMessage(message.data())
            const roomIndex = this.rooms.findIndex(
              r => room.roomId === r.roomId,
            )
            this.rooms[roomIndex].lastMessage = lastMessage
            this.rooms = [...this.rooms]
          })
          if (this.loadingLastMessageByRoom < this.rooms.length) {
            this.loadingLastMessageByRoom += 1

            if (this.loadingLastMessageByRoom === this.rooms.length) {
              this.loadingRooms = false
              this.roomsLoadedCount = this.rooms.length
            }
          }
        })

      this.roomsListeners.push(listener)
    },

    formatLastMessage(message) {
      if (!message.timestamp) return

      let { content } = message
      if (message.file) {
        content = `${message.file.name}.${message.file.extension
          || message.file.type}`
      }

      return {
        ...message,
        ...{
          content,
          timestamp: this.formatTimestamp(
            new Date(message.timestamp.seconds * 1000),
            message.timestamp,
          ),
          distributed: true,
          seen: message.sender_id === this.currentUserId ? message.seen : null,
          new:
            message.sender_id !== this.currentUserId
            && (!message.seen || !message.seen[this.currentUserId]),
        },
      }
    },

    formatTimestamp(date, timestamp) {
      const timestampFormat = this.isSameDay(date, new Date())
        ? 'HH:mm'
        : 'DD/MM/YY'
      const result = moment(timestamp.toDate()).format(timestampFormat)
      return timestampFormat === 'HH:mm' ? `Today, ${result}` : result
    },
    isSameDay(d1, d2) {
      return (
        d1.getFullYear() === d2.getFullYear()
        && d1.getMonth() === d2.getMonth()
        && d1.getDate() === d2.getDate()
      )
    },
    messagesRef(roomId) {
      // console.log(roomId)
      // console.log(this.roomsRef)
      return this.roomsRef.doc(roomId).collection('messages')
    },
    fetchMessages({ room, options = {} }) {
      if (options.reset) this.resetMessages()
      if (this.endMessages && !this.startMessages) {
        this.messagesLoaded = true
        return
      }

      const ref = this.messagesRef(room.roomId)
      let query = ref.orderBy('timestamp', 'desc').limit(this.messagesPerPage)
      if (this.startMessages) query = query.startAfter(this.startMessages)

      this.selectedRoom = room.roomId

      query.get().then(messages => {
        // console.log('load message')
        // console.log(messages)

        if (this.selectedRoom !== room.roomId) return

        if (messages.empty) this.messagesLoaded = true

        if (this.startMessages) this.endMessages = this.startMessages
        this.startMessages = messages.docs[messages.docs.length - 1]

        let listenerQuery = ref.orderBy('timestamp')

        if (this.startMessages) listenerQuery = listenerQuery.startAfter(this.startMessages)
        if (this.endMessages) listenerQuery = listenerQuery.endAt(this.endMessages)

        if (options.reset) this.messages = []

        messages.forEach(message => {
          const formattedMessage = this.formatMessage(room, message)
          this.messages.unshift(formattedMessage)
        })

        const listener = listenerQuery.onSnapshot(snapshots => {
          this.listenMessages(snapshots, room)
        })
        this.listeners.push(listener)
      })
    },

    listenMessages(messages, room) {
      messages.forEach(message => {
        const formattedMessage = this.formatMessage(room, message)
        const messageIndex = this.messages.findIndex(
          m => m._id === message.id,
        )

        if (messageIndex === -1) {
          this.messages = this.messages.concat([formattedMessage])
        } else {
          this.$set(this.messages, messageIndex, formattedMessage)
        }

        this.markMessagesSeen(room, message)
      })
    },

    markMessagesSeen(room, message) {
      if (
        message.data().sender_id !== this.currentUserId
        && (!message.data().seen || !message.data().seen[this.currentUserId])
      ) {
        // console.log('mark message seen')
        this.messagesRef(room.roomId)
          .doc(message.id)
          .update({
            [`seen.${this.currentUserId}`]: new Date(),
          })
      }
    },
    formatMessage(room, message) {
      const senderUser = room.users.find(
        user => message.data().sender_id === user._id,
      )
      // console.log(senderUser.username)
      return {
        ...message.data(),
        ...{
          senderId: message.data().sender_id,
          _id: message.id,
          seconds: message.data().timestamp.seconds,
          timestamp: moment(message.data().timestamp.toDate()).format(
            'HH:mm',
          ),
          date: moment(message.data().timestamp.toDate()).format(
            'DD MMMM YYYY',
          ),
          username: senderUser ? senderUser.username : null,
          avatar: senderUser ? senderUser.avatar : null,
          distributed: true,
        },
      }
    },
    async sendMessage({
      content, roomId, file, replyMessage,
    }) {
      const senderIdOrg = this.currentUserId
      // console.log(this.match.org_id)
      this.currentUserId = this.match.org_id
      if (roomId.includes('Game7')) {
        this.currentUserId = 'game7-moderator'
      }
      const message = {
        sender_id_raw: senderIdOrg,
        sender_id: this.currentUserId,
        content,
        timestamp: new Date(),
      }

      if (file) {
        message.file = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.localUrl,
        }
        if (file.audio) {
          message.file.audio = true
          message.file.duration = file.duration
        }
      }

      if (replyMessage) {
        message.replyMessage = {
          _id: replyMessage._id,
          content: replyMessage.content,
          sender_id: replyMessage.senderId,
        }

        if (replyMessage.file) {
          message.replyMessage.file = replyMessage.file
        }
      }

      const { id } = await this.messagesRef(roomId).add(message)

      if (file) this.uploadFile({ file, messageId: id, roomId })

      this.roomsRef.doc(roomId).set(
        {
          lastUpdated: new Date(),
        },
        { merge: true },
      )
    },
    async sendMessageReaction({
      reaction, remove, messageId, roomId,
    }) {
      // console.log(this.currentUserId)
      const dbAction = remove
        ? db.FieldValue.arrayRemove(this.currentUserId)
        : db.FieldValue.arrayUnion(this.currentUserId)

      await this.messagesRef(roomId)
        .doc(messageId)
        .update({
          [`reactions.${reaction.name}`]: dbAction,
        })
    },
    async uploadFile({ file, messageId, roomId }) {
      let type = file.extension || file.type
      if (type === 'svg' || type === 'pdf') {
        type = file.type
      }

      const uploadFileRef = this.filesRef
        .child(this.currentUserId)
        .child(messageId)
        .child(`${file.name}.${type}`)

      await uploadFileRef.put(file.blob, { contentType: type })
      const url = await uploadFileRef.getDownloadURL()
      await this.messagesRef(roomId)
        .doc(messageId)
        .update({
          'file.url': url,
        })
    },
    openFile({ message }) {
      window.open(message.file.url, '_blank')
    },

    async editMessage({
      messageId, newContent, roomId, file,
    }) {
      const newMessage = { edited: new Date() }
      newMessage.content = newContent

      if (file) {
        newMessage.file = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.url || file.localUrl,
        }
        if (file.audio) {
          newMessage.file.audio = true
          newMessage.file.duration = file.duration
        }
      } else {
        newMessage.file = db.FieldValue.delete()
      }

      await this.messagesRef(roomId)
        .doc(messageId)
        .update(newMessage)

      if (file) this.uploadFile({ file, messageId, roomId })
    },

    async deleteMessage({ message, roomId }) {
      await this.messagesRef(roomId)
        .doc(message._id)
        .update({ deleted: new Date() })

      const { file } = message

      if (file) {
        const deleteFileRef = this.filesRef
          .child(this.currentUserId)
          .child(message._id)
          .child(`${file.name}.${file.extension || file.type}`)

        await deleteFileRef.delete()
      }
    },
    updateUserOnlineStatus() {},
  },
}
</script>
